import {AfterContentInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {map, Observable, Subject} from 'rxjs';
import {MeasuringPoint, Sensor} from '../model/Sensor';
import {Station} from '../model/Station';
import {StationsService} from '../stations/stations.service';
import {DaysToHours} from './daysToHours.enum';
import {formatDate} from '@angular/common';

type MappedStationData = {sensorType: string; inactive: boolean; measuringPoints: MeasuringPoint[]};

@Component({
  selector: 'met-diagrams',
  templateUrl: './diagrams.component.html',
  styleUrls: ['./diagrams.component.scss'],
})
export class DiagramsComponent implements AfterContentInit, OnDestroy, OnInit {

  appId!: string;

  stationType!: string;

  stationId: string | null = '';

  exampleDate: Date = new Date();

  mappedStations$: Observable<Map<DaysToHours, MappedStationData[]>> | undefined;

  sensorData$: Observable<any> | undefined;

  selectedTimeInterval: DaysToHours = DaysToHours.week;

  selectedBackDays: number = 1;

  private unsubscribe$ = new Subject<void>();

  constructor(private stationService: StationsService, private route: ActivatedRoute) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.stationId = this.route.snapshot.paramMap.get('stationId');
    this.mapSensorDataToObject(this.selectedTimeInterval);
    this.mappedStations$ = this.stationService.getStation(this.stationId).pipe(
      map((station: Station) => {
        this.appId = station.appId;
        this.stationType = station.stationType;
        return station.sensors;
      }),
      map((sensors: Sensor[]) =>
        sensors.map((sensor) => ({
          sensorType: sensor.sensorType,
          inactive: sensor.inactive,
          measuringPoints: this.addEmptyMeasuringPoints(sensor.measuringPoints),
        }))
      ),
      map((mappedStations: MappedStationData[]) => {
        let mappedTimeWithStationData = new Map<DaysToHours, MappedStationData[]>();
        mappedTimeWithStationData.set(
          DaysToHours.twoDays,
          mappedStations.map((sensor) => ({
            ...sensor,
            measuringPoints: sensor.measuringPoints.slice(0, 47),
          }))
        );
        mappedTimeWithStationData.set(
          DaysToHours.week,
          mappedStations.map((sensor) => ({
            ...sensor,
            measuringPoints: sensor.measuringPoints.slice(0, 167),
          }))
        );
        mappedTimeWithStationData.set(
          DaysToHours.month,
          mappedStations.map((sensor) => ({
            ...sensor,
            measuringPoints: sensor.measuringPoints.slice(0, 671),
          }))
        );
        return mappedTimeWithStationData;
      })
    );
  }

  ngAfterContentInit(): void {
    this.mapSensorDataToObject(this.selectedTimeInterval);
  }

  mapSensorDataToObject(timeInterval: DaysToHours) {
    this.selectedTimeInterval = timeInterval;
  }

  backDaysChange(days: number) {
    this.selectedBackDays = days;
  }

  private addEmptyMeasuringPoints(measuringPoints: MeasuringPoint[]) {
    for(let i = 0; i<this.selectedTimeInterval; i++) {
      let newDateMilli = new Date().valueOf()-((i+8)*60*60*1000)
      let newDate = new Date(newDateMilli)
      newDate.setMinutes(newDate.getMinutes()-newDate.getMinutes())
      newDate.setSeconds(newDate.getSeconds()-newDate.getSeconds())
      let date = formatDate(newDate,"yyyy-MM-ddTHH:mm:ss",'de')

      let foundMeasuringPoints = []
      foundMeasuringPoints = measuringPoints.filter(measuringPoint => {
        return measuringPoint.timeStamp == date
      })
      if(foundMeasuringPoints.length < 1) {
        let newEmptyMeasuringPoint:MeasuringPoint = {
          timeStamp: date,
          value: ''
        }
        measuringPoints.push(newEmptyMeasuringPoint)
      }
    }
    measuringPoints.sort((measuringPointA, measuringPointB) => {
      return new Date(measuringPointB.timeStamp).valueOf() - new Date(measuringPointA.timeStamp).valueOf()
    })
    return measuringPoints;
  }
}
